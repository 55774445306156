import ApiService from "./ApiService";

export default class OrderService {
  static getUsFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count", {
      baseURL: `https://api.admin-us.online/api`,
    });
  static getUsUnreadMailCount = () =>
    ApiService.get("/admin/get-unread-mail-count", {
      baseURL: `https://api.admin-us.online/api`,
    });
  static getPhUnreadMailCount = () =>
    ApiService.get("common/get-unread-mail-count", {
      baseURL: `https://api.admin-ph.online/api`,
    });

  static getIndonesiaUnreadMailCount = () =>
    ApiService.get("common/get-unread-mail-count", {
      baseURL: `https://api.admin-ind.online/api/`,
    });

  static getMalUnreadMailCount = () =>
    ApiService.get("common/get-unread-mail-count", {
      baseURL: `https://api.admin-malaysia-arrival.com/api`,
    });

  static getSgFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count", {
      baseURL: `https://api.admin-sg.online/api/`,
    });

  static getSgUnreadMailCount = () =>
    ApiService.get("/admin/get-mail-count", {
      baseURL: `https://api.admin-sg.online/api/`,
    });

  static getVnFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count", {
      baseURL: `https://api.admin-vn.online/api/`,
    });
  static getUkFolderCount = () =>
    ApiService.get("/admin/get-folder-count", {
      baseURL: `https://api.admin-uk.online/api/`,
    });

  static getVnUnreadMailCount = () =>
    ApiService.get("/common/get-unread-mail-count", {
      baseURL: `https://api.admin-vn.online/api/`,
    });

  static getEvwFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count", {
      baseURL: `https://admin-evw.online:9000/api/`,
    });

  static getEvwUnreadMailCount = () =>
    ApiService.get("/common/get-unread-mail-count", {
      baseURL: `https://admin-evw.online:9000/api/`,
    });

  static getTurkeyFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count", {
      baseURL: `http://dev.evisa-turkey.online:4000/api/`,
    });

  static getCaFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count", {
      baseURL: `https://api.admin-ca.online/api/`,
    });

  static getCaUnreadMailCount = () =>
    ApiService.get("/common/get-unread-mail-count", {
      baseURL: `https://api.admin-ca.online/api/`,
    });

  static getEgFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count", {
      baseURL: `https://api.admin-eg.online/api/`,
    });

  static getKrFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count", {
      baseURL: `https://api.admin-sk.online/api/`,
    });

  static getMalFolderCount = () =>
    ApiService.get("/admin/get-folder-count", {
      baseURL: `https://api.admin-malaysia-arrival.com/api`,
    });

  static getPhFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count", {
      baseURL: `https://api.admin-ph.online/api/`,
    });

  static getIndonesiaFolderCount = () =>
    ApiService.get("/admin/get-folder-count", {
      baseURL: `https://api.admin-ind.online/api/`,
    });
  static getIndonesiaEvoaFolderCount = () =>
    ApiService.get("/admin/get-all-folder-count", {
      baseURL: `https://api.admin-evoa.online/api/`,
    });

  static getAllDomainList = (id) =>
    ApiService.get(`/master/get-all-domain-list/${id}`, {
      baseURL: process.env.REACT_APP_API_URL,
      // baseURL: `https://api.myadmin-panel.com/api`,
    });

  static getAllLandingList = () =>
    ApiService.get("/master/get-all-landing-site-list", {
      baseURL: process.env.REACT_APP_API_URL,
      // baseURL: `https://api.myadmin-panel.com/api`,
    });

  static updateSiteButton = (data) =>
    ApiService.post("/master/update-site-button", data);
  static cookieStatus = (data) =>
    ApiService.post("/master/cookie-status-update", data);

  static verificationDomain = (data) =>
    ApiService.post("/master/verification-domain-list", data);
}
