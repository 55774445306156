import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import OrderTotalBox from "../../../Components/OrderTotalBox/OrderTotalBox";

const TotalMailCount = () => {
    const [estaFolderCount, setEstaFolderCount] = useState([]);
    const [sriLankaFolderCount, setSriLankaFolderCount] = useState([]);
    const [uKeTAFolderCount, setUKeTAFolderCount] = useState([]);
    const [canadaFolderCount, setCanadaFolderCount] = useState([]);
    const [newZealandFolderCount, setNewZealandFolderCount] = useState([]);
    const [japanFolderCount, setJapanFolderCount] = useState([]);
    const [arubaFolderCount, setArubaFolderCount] = useState([]);
    const [malaysiaArrivalCount, setMalaysiaArrivalCount] = useState([]);
    const [indonesiaCount, setIndonesiaCount] = useState([]);
    const [philippinesCount, setPhilippinesCount] = useState([]);
    const [singaporeCount, setSingaporeCount] = useState([]);
    const [indonesiaEvoaCount, setIndonesiaEvoaCount] = useState([]);
    const [ukCount, setUkCount] = useState([]);
    const [vietnam, setVietnamCount] = useState([]);
    const [egyptCount, setEgyptCount] = useState([]);
    const [koreaCount, setKoreaCount] = useState([]);

    const NewOrderscount = useSelector((state) => state?.order?.NewOrderscount);

    const estaMailURL = "https://estaportal.online/imapApiUnreadMailCountSubFolder.php";
    const srilankaMailURL = "https://srilanka-eta.com/admin/imapApiUnreadMailCount.php";
    const canadaMailURL = "https://canada-eta.online/imapApiUnreadMailCountSubFolder.php";
    const newZealandURL = "https://newzealand-td.online/imapApiUnreadMailCountSubFolder.php";
    const japanURL = "https://japan-arrivalcard.com/imapApiUnreadMailCountSubFolder.php";
    const arubaURL = "https://aruba-edcard.online/imapApiUnreadMailCountSubFolder.php";
    const malaysiaArrivalMailURL = "https://malaysia-arrival-card.online/imapApiUnreadMailCountSubFolder.php";
    const indonesiaEcdServicesURL = "https://indonesia-ecd.services/imapApiUnreadMailCountSubFolder.php";
    const philippinesURL = "https://philippines-etd.com/imapApiUnreadMailCountSubFolder.php";
    const singaporeURL = "https://sg-arrivalcard.online/imapApiUnreadMailCountSubFolder.php";
    const indoensiaEvoaURL = "https://indonesia-evoa.online/imapApiUnreadMailCountSubFolder.php";
    const egyptUnreadMailCountURL = "https://egypt-portal.site/imapApiUnreadMailCountSubFolder.php";
    const TurkeyMailURL = "https://evisa-turkey.online/admin/imapApiUnreadMailCount.php";
    const ukMailURL = "https://uk-eta.online/imapApiUnreadMailCountSubFolder.php";
    const vietnamURL = "https://vietnamportal.online/imapApiUnreadMailCountSubFolder.php";
    const koreaURL = "https://korea-portal.online/imapApiUnreadMailCountSubFolder.php";

    let FinalData = [];

    useEffect(() => {

        let estaData = {
            domain: "Esta Site",
            email: "inquiries@estaportal.online",
            loginUrl: "https://estaportal.online/admin",
        };
        axios
            .get(estaMailURL)
            .then((response) => {
                estaData = { ...estaData, mailCountData: response?.data }
                setEstaFolderCount([estaData]);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    useEffect(() => {
        let canadaData = {
            domain: "Canada Site",
            email: "inquiries@canada-eta.online",
            loginUrl: "https://canada-eta.online/admin",
        };
        axios
            .get(canadaMailURL)
            .then((response) => {

                canadaData = { ...canadaData, mailCountData: response?.data }
                setCanadaFolderCount([canadaData]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        let newZealandData = {
            domain: "New Zealand Site",
            email: "inquiries@newzealand-td.online",
            loginUrl: "https://admin-nz.online",
        };
        axios
            .get(newZealandURL)
            .then((response) => {

                newZealandData = { ...newZealandData, mailCountData: response?.data }
                setNewZealandFolderCount([newZealandData]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        let japanData = {
            domain: "Japan Site",
            email: "inquiries@japan-arrivalcard.com",
            loginUrl: "https://admin-jp.online",
        };
        axios
            .get(japanURL)
            .then((response) => {
                japanData = { ...japanData, mailCountData: response?.data }
                setJapanFolderCount([japanData]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        let arubaData = {
            domain: "Aruba Site",
            email: "inquiries@aruba-edcard.online",
            loginUrl: "https://adminaruba.online",
        };
        axios
            .get(arubaURL)
            .then((response) => {
                arubaData = { ...arubaData, mailCountData: response?.data }
                setArubaFolderCount([arubaData]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {

        let malaysiaData = {
            domain: "Malaysia Arrival Card Site",
            email: "inquiries@malaysia-arrival-card.online",
            loginUrl: "https://admin-malaysia-arrival.com/",
        };
        axios
            .get(malaysiaArrivalMailURL)
            .then((response) => {
                malaysiaData = { ...malaysiaData, mailCountData: response?.data }
                setMalaysiaArrivalCount([malaysiaData]);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    useEffect(() => {
        let indonesiaData = {
            domain: "Indonesia ECD Site",
            email: "inquiries@indonesia-ecd.services",
            loginUrl: "https://admin-ind.online/",
        };
        axios
            .get(indonesiaEcdServicesURL)
            .then((response) => {
                indonesiaData = { ...indonesiaData, mailCountData: response?.data }
                setIndonesiaCount([indonesiaData]);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    useEffect(() => {
        let philippinesData = {
            domain: "Philippines Site",
            email: "inquiries@philippines-etd.com",
            loginUrl: "https://admin-ph.online/",
        };
        axios
            .get(philippinesURL)
            .then((response) => {
                philippinesData = { ...philippinesData, mailCountData: response?.data }
                setPhilippinesCount([philippinesData]);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    useEffect(() => {
        let singaporeData = {
            domain: "Singapore Site",
            email: "inquiries@sg-arrivalcard.online",
            loginUrl: "https://sg-arrivalcard.online/admin",
        };
        axios
            .get(singaporeURL)
            .then((response) => {
                singaporeData = { ...singaporeData, mailCountData: response?.data }
                setSingaporeCount([singaporeData]);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    useEffect(() => {
        let indonesiaEvoaData = {
            domain: "Indonesia Evoa Site",
            email: "inquiries@indonesia-evoa.online",
            loginUrl: "https://admin-evoa.online/",
        };
        axios
            .get(indoensiaEvoaURL)
            .then((response) => {
                indonesiaEvoaData = { ...indonesiaEvoaData, mailCountData: response?.data }
                setIndonesiaEvoaCount([indonesiaEvoaData]);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    useEffect(() => {
        let ukMainData = {
            domain: "Uk Site",
            email: "inquiries@uk-eta.online",
            loginUrl: "https://admin-uk.online/",
        };
        axios
            .get(ukMailURL)
            .then((response) => {
                ukMainData = { ...ukMainData, mailCountData: response?.data }
                setUkCount([ukMainData]);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    useEffect(() => {
        let vietnamData = {
            domain: "Vietnam Site",
            email: "inquiries@vietnamportal.online",
            loginUrl: "https://admin-vn.online/",
        };
        axios
            .get(vietnamURL)
            .then((response) => {
                vietnamData = { ...vietnamData, mailCountData: response?.data }
                setVietnamCount([vietnamData]);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    useEffect(() => {
        let egyptData = {
            domain: "Egypt Site",
            email: "inquiries@egypt-portal.site",
            loginUrl: "https://admin-eg.online/",
        };
        axios
            .get(egyptUnreadMailCountURL)
            .then((response) => {
                egyptData = { ...egyptData, mailCountData: response?.data }
                setEgyptCount([egyptData]);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    useEffect(() => {
        let koreaData = {
            domain: "Korea Site",
            email: "inquiries@korea-portal.online",
            loginUrl: "https://admin-sk.online/",
        };
        axios
            .get(koreaURL)
            .then((response) => {
                koreaData = { ...koreaData, mailCountData: response?.data }
                setKoreaCount([koreaData]);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    setTimeout(function () {
        window.location.reload();
    }, 60000 * 5);

    const handleClick = () => {
        window.location.reload();
    }

    // const turkeyFolderCountArray = Array.from(turkeyFolderCount);
    if (
        NewOrderscount?.length > 0 ||
        estaFolderCount?.length > 0 ||
        sriLankaFolderCount?.length > 0 ||
        uKeTAFolderCount?.length > 0 ||
        // turkeyFolderCountArray?.length > 0 &&
        canadaFolderCount?.length > 0 ||
        newZealandFolderCount?.length > 0 ||
        japanFolderCount?.length > 0 ||
        arubaFolderCount?.length > 0 ||
        malaysiaArrivalCount?.length > 0 ||
        indonesiaCount?.length > 0 ||
        philippinesCount?.length > 0 ||
        singaporeCount?.length > 0 ||
        indonesiaEvoaCount?.length > 0 ||
        egyptCount?.length > 0 ||
        ukCount?.length > 0 ||
        vietnam?.length > 0 ||
        koreaCount?.length > 0
    ) {
        FinalData = [...estaFolderCount, ...sriLankaFolderCount, ...uKeTAFolderCount, ...canadaFolderCount, ...newZealandFolderCount,...arubaFolderCount, ...japanFolderCount, ...malaysiaArrivalCount, ...indonesiaCount, ...philippinesCount, ...singaporeCount, ...indonesiaEvoaCount, ...egyptCount, ...ukCount, ...vietnam, ...koreaCount];
    }

    const tableData = FinalData;

    return (
        <StyledContainer>
            <StyledPageTitle>
                <h1>Total Mail Count</h1>
                <button type="button" className="btn btn-success" onClick={handleClick}>Refresh</button>
            </StyledPageTitle>
            <form>
                <StyledOrderBlock>
                    <div className="table-block table-responsive">
                        <OrderTotalBox data={tableData} />
                    </div>
                </StyledOrderBlock>
            </form>
        </StyledContainer>
    );
};

export default TotalMailCount;
